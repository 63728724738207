import React, { createContext } from 'react'
import { AppDialogRef } from './AppDialog'
import { OverridableStringUnion } from '@mui/types'
import { ButtonPropsColorOverrides } from '@mui/material'

export interface AppDialogCallToAction {
  proceedHandler: () => void
  text: string
  colour?:
    | OverridableStringUnion<
        | 'info'
        | 'inherit'
        | 'primary'
        | 'secondary'
        | 'success'
        | 'error'
        | 'warning',
        ButtonPropsColorOverrides
      >
    | undefined
}

interface AppDialogContext {
  dialogRef: React.MutableRefObject<AppDialogRef | null>
}

/**
 * A context to provide the AppDialogRef, isDisabled, and callToAction state created in the AppDialogProvider to
 * the useAppDialog() hook in whatever child components it may be used in.
 */
const AppDialogContext = createContext<AppDialogContext>({
  dialogRef: { current: null },
})
export default AppDialogContext
