// This is a copy of the same file in the Mercury repo
// TODO - pull this into a shared library
import loadedStorage from './loadedStorage'

/**
 * Checks if debugging has been enabled in this browser context. To enable
 * debugging set the local storage key '@@loaded/debugEnabled' to a JS truthy
 * value.
 * @returns
 */
export default function () {
  return !!loadedStorage.getItem('debugEnabled')
}
