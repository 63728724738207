import appConfig from '../utils/appConfig'
import logger from '../utils/logger'
import { getAuthToken } from '../utils/auth'

enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

/**
 * Execute a GET query against the Admin Api.
 *
 * @param path
 * @param useOldApi - use the old Admin Api (for non-migrated requests)
 * @returns the data parsed from the JSON response received
 */
async function get(path: string, useOldApi = false) {
  return sendRequest(HttpMethod.GET, path, undefined, useOldApi)
}

/**
 * Execute a POST query against the Admin Api.
 *
 * @param path
 * @param requestBody
 * @param useOldApi - use the old Admin Api (for non-migrated requests)
 * @returns the data parsed from the JSON response received
 */
async function post(path: string, requestBody: object, useOldApi = false) {
  return sendRequest(HttpMethod.POST, path, requestBody, useOldApi)
}

/**
 * Execute a PUT query against the Admin Api.
 *
 * @param path
 * @param useOldApi - use the old Admin Api (for non-migrated requests)
 * @returns the data parsed from the JSON response received
 */
async function put(path: string, requestBody: object, useOldApi = false) {
  return sendRequest(HttpMethod.PUT, path, requestBody, useOldApi)
}

/**
 * Execute a DELETE query against the Admin Api.
 *
 * @param path
 * @param useOldApi - use the old Admin Api (for non-migrated requests)
 * @returns the data parsed from the JSON response received
 */
async function remove(path: string, requestBody?: object, useOldApi = false) {
  return sendRequest(HttpMethod.DELETE, path, requestBody, useOldApi)
}

/**
 * Actually send a request to the Admin Api, designed to be called by each of
 * the helper methods above.
 *
 * @param method
 * @param path
 * @param requestBody
 * @returns
 */
async function sendRequest(
  method: HttpMethod,
  path: string,
  requestBody?: object,
  useOldApi = false
) {
  // Normalise the path and remove a leading slash, we don't want to end up
  // with two of these when constructing the url as the API won't tolerate
  // these, instead returning a 404.
  const trimmedPath = path.replace(/^\//, '')

  const response = await fetch(
    `${
      useOldApi
        ? await appConfig.get('webApiUrl')
        : await appConfig.get('adminApiUrl')
    }/${trimmedPath}`,
    {
      method: method,
      headers: {
        Accept: 'application/json',
        authorization: `Bearer ${getAuthToken()}`,
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(requestBody),
    }
  )
  if (!response.ok) {
    logger.error(`received HTTP ${response.status} sending data to ${path}`)

    if (response.status === 401) {
      logger.error('received HTTP 401, redirecting to sign-in')
      window.location.href = '/sign-in'
    }

    throw new Error('API client exception')
  }

  return response.status === 204 ? '' : response.json()
}

/**
 * Helper that can wrap around an async function and will make sure that any
 * error thrown is logged to the console.
 * @param fn The async function to wrap
 * @returns A wrapped version of the function that will log errors thrown
 */
function wrapAsyncWithErrorLogging<T>(fn: (...args: any[]) => Promise<T>) {
  return async (...args: any[]) => {
    try {
      return await fn(...args)
    } catch (error) {
      logger.error(error)
      throw error
    }
  }
}

export default {
  get: wrapAsyncWithErrorLogging(get),
  post: wrapAsyncWithErrorLogging(post),
  put: wrapAsyncWithErrorLogging(put),
  remove: wrapAsyncWithErrorLogging(remove),
}
