import { NonIndexRouteObject } from 'react-router-dom'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Box, Grid, SvgIconTypeMap, Typography } from '@mui/material'
import DashboardIcon from '@mui/icons-material/Dashboard'
import BusinessIcon from '@mui/icons-material/Business'
import PeopleIcon from '@mui/icons-material/People'
import StorageIcon from '@mui/icons-material/Storage'
import FastfoodIcon from '@mui/icons-material/Fastfood'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import ConstructionIcon from '@mui/icons-material/Construction'

import PageLoadingSupport from 'src/components/app/PageLoadingSupport'
import { default as AppRoutes } from './index'

export enum AppRouteType {
  GROUP = 'group',
  PAGE = 'page',
}

export interface AppRouteMenu {
  icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>
  text: string
  section: string
  type?: string
}

export interface AppRouteHandle {
  menu: AppRouteMenu
  pageTitle?: string
  type?: AppRouteType
}

export interface AppRouteDefinition
  extends Omit<NonIndexRouteObject, 'children'> {
  handle?: AppRouteHandle
  children?: AppRouteDefinition[]
}

/**
 * Temporary component to display on page we expect to implement, but are still
 * to be written.
 * @returns
 */
const ComingSoon = () => (
  <Box
    marginLeft={0}
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap',
      textAlign: 'center',
      height: 'calc(100vh - 121px)',
    }}
  >
    <Grid container sx={{ marginTop: '-60px' }}>
      <Grid item xs={12}>
        <ConstructionIcon sx={{ fontSize: '92px' }} color="disabled" />
      </Grid>
      <Grid item xs={12} justifyContent="center" alignItems="center">
        <Typography component="h2" variant="h4">
          Coming Soon
        </Typography>
        <Typography variant="body1">
          This page is not yet migrated, please use the older version for now.
        </Typography>
      </Grid>
    </Grid>
  </Box>
)

/**
 * A `react-router` router definition array. Each object in this array
 * represents a url that should have a React component rendered on it.
 * The `handle` property is used to provide metadata about the route
 * that is used during rendering of each page.
 *
 * Note that while it might seem tidy to push the `PageLoadingSupport`
 * component into each of the page definitions themselves it's not done
 * that way so that we don't end up with the code for that included in
 * each page bundle. Our app bundler is splitting the pages into separate
 * files that get downloaded, this way the `PageLoadingSupport` component
 * is only included once in the main bundle.
 */
const appRoutes: AppRouteDefinition[] = [
  {
    path: '/',
    element: (
      <PageLoadingSupport>
        <AppRoutes.Dashboard.Component />,
      </PageLoadingSupport>
    ),
    loader: AppRoutes.Dashboard.Loader,
    handle: {
      pageTitle: 'Dashboard',
      menu: {
        icon: <DashboardIcon />,
        text: 'Dashboard',
        section: 'main',
      },
    },
  },
  {
    path: '/companies/',
    handle: {
      pageTitle: 'Companies',
      menu: {
        icon: <BusinessIcon />,
        text: 'Companies',
        section: 'main',
      },
    },
    children: [
      {
        path: '',
        element: (
          <PageLoadingSupport>
            <AppRoutes.CompanyList.Component />
          </PageLoadingSupport>
        ),
        loader: AppRoutes.CompanyList.Loader,
      },
      {
        path: ':id',
        element: (
          <PageLoadingSupport>
            <AppRoutes.CompanyShow.Component />
          </PageLoadingSupport>
        ),
        loader: AppRoutes.CompanyShow.Loader,
      },
    ],
  },
  {
    path: '/users',
    element: (
      <PageLoadingSupport>
        <AppRoutes.Users.Component />,
      </PageLoadingSupport>
    ),
    loader: AppRoutes.Users.Loader,
    handle: {
      pageTitle: 'Users',
      menu: {
        icon: <PeopleIcon />,
        text: 'Users',
        section: 'main',
      },
    },
  },
  {
    path: '/stock',
    element: (
      <PageLoadingSupport>
        <AppRoutes.Stock.Component />,
      </PageLoadingSupport>
    ),
    loader: AppRoutes.Stock.Loader,
    handle: {
      pageTitle: 'Stock',
      menu: {
        icon: <FastfoodIcon />,
        text: 'Stock',
        section: 'main',
      },
    },
  },
  {
    handle: {
      type: AppRouteType.GROUP,
      pageTitle: 'Databases',
      menu: {
        icon: <StorageIcon />,
        text: 'Databases',
        section: 'monitoring',
      },
    },
    children: [
      {
        path: '/databases/company',
        element: <ComingSoon />,
        handle: {
          pageTitle: 'Company Databases',
          menu: {
            icon: <StorageIcon />,
            text: 'Company',
            section: 'monitoring',
          },
        },
      },
      {
        path: '/databases/pos',
        element: <ComingSoon />,
        handle: {
          pageTitle: 'POS Databases',
          menu: {
            icon: <StorageIcon />,
            text: 'POS',
            section: 'monitoring',
          },
        },
      },
    ],
  },
  {
    path: '/data-sync',
    element: <ComingSoon />,
    handle: {
      pageTitle: 'Data Sync',
      menu: {
        icon: <CloudSyncIcon />,
        text: 'Data Sync',
        section: 'monitoring',
      },
    },
  },
]
export default appRoutes
