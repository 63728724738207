import React, { ReactNode, useRef } from 'react'
import AppDialogContext from './AppDialogContext'
import AppDialog, { AppDialogRef } from './AppDialog'

/**
 * A provider component that wraps the entire app and provides the context
 * for the useAppDialog() hook to work correctly.
 */
export default function DialogProvider({ children }: { children: ReactNode }) {
  // If we are wanting add more hooks to add functionaloty for the app dialog.
  // Do NOT add them here. Add them in to the AppDialogRef. If hooks/actions are not
  // included in the dialogRef then each call to them will cause the entire app to
  // re-render (performance killer!). This is due the the AppDialogProvider wrapping
  // the app (any re-render to the app dialog will re-render the whole app).
  const dialogRef = useRef<AppDialogRef | null>(null)

  return (
    <>
      <AppDialogContext.Provider
        value={{
          dialogRef,
        }}
      >
        {children}
        <AppDialog ref={dialogRef} />
      </AppDialogContext.Provider>
    </>
  )
}
