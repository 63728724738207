import { styled } from '@mui/material/styles'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'

interface AppBarProps extends MuiAppBarProps {
  drawerWidth?: number
}
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'drawerWidth',
})<AppBarProps>(({ drawerWidth }) => ({
  marginLeft: drawerWidth,
  width: `calc(100% - ${drawerWidth}px)`,
}))
export default AppBar
