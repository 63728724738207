// This is a copy of the same file in the Mercury repo
// TODO - pull this into a shared library
const STATE = {
  enabled: false,
}

/**
 * Enable logging to the console
 */
function enable() {
  STATE.enabled = true
}

function debug(...args: any[]) {
  STATE.enabled && console.debug(...args)
}
function error(...args: any[]) {
  STATE.enabled && console.error(...args)
}
function info(...args: any[]) {
  STATE.enabled && console.info(...args)
}
function trace(...args: any[]) {
  STATE.enabled && console.trace(...args)
}
function warn(...args: any[]) {
  STATE.enabled && console.warn(...args)
}

export default {
  enable,
  debug,
  error,
  info,
  trace,
  warn,
}
