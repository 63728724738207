/***
 * Given a string, returns a color in hex format that has been procedurally
 * generated based on the string characters.
 *
 * The algorithm is consistent, so the same string will always return the same
 * color.
 */
export default function stringToColor(string: string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}
