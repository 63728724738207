import queryClient from './queryClient'

/**
 * Returns the value of the configuration item with the given key if it is
 * defined. If a config item is not defined, a provided default will be
 * returned, else the return value will be undefined.
 * @param key
 * @returns
 */
async function get(key: string, defaultValue = undefined): Promise<string> {
  // react-query caches the query for the configuration for us, we only
  // actually make the API call to get configuration values periodically
  const config = await queryClient.fetchQuery(
    'appConfig',
    async () => {
      const response = await fetch('/app-ui-config')
      if (!response.ok) {
        throw new Error('Failed to load app config')
      }
      return response.json()
    },
    {
      staleTime: 1000 * 60 * 5, // 5 minutes
      cacheTime: 1000 * 60 * 60, // 1 hour
    }
  )

  return config[key] || defaultValue
}

export default {
  get,
}
