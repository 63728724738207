import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer, { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'

import logoImg1Src from '../assets/logo-name-only-large.png'
import logoImg2Src from '../assets/logo-round.png'

import Copyright from './components/Copyright'
import AppMenu from './AppMenu'

interface DrawerProps extends MuiDrawerProps {
  drawerWidth: number
}
const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'drawerWidth',
})<DrawerProps>(({ drawerWidth }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    backgroundColor: 'inherit',
  },
}))

/**
 * Main left hand menu of the application.
 * @param param0
 * @returns
 */
const AppNavigation = ({ drawerWidth, ...props }: DrawerProps) => (
  <StyledDrawer
    variant="permanent"
    drawerWidth={drawerWidth}
    PaperProps={{ elevation: 1 }}
  >
    <Toolbar disableGutters={true}>
      <Box sx={{ pl: 2 }}>
        <img src={logoImg1Src} height={32} />
      </Box>
    </Toolbar>

    <AppMenu />

    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flex: 1,
        filter: 'grayscale(1) opacity(0.07)',
      }}
    >
      <img src={logoImg2Src} height={128} />
    </Box>

    <Copyright sx={{ pt: 4, pb: 4 }} />
  </StyledDrawer>
)
export default AppNavigation
