import { useRouteError } from 'react-router-dom'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import WarningAmber from '@mui/icons-material/WarningAmber'

import logger from 'src/utils/logger'

/**
 * Simple error component that is intended for display in the main content
 * section of the app when an error occurs preventing it being rendered.
 * @returns
 */
export default function FullPageError({
  marginLeft = 0,
  errorMessage = "Sorry something unexpected has happened and we can't load the page you were trying to see.",
  secondaryMessage = 'If this keeps happening please contact our support team.',
}: FullPageErrorProps) {
  const error = useRouteError()
  logger.error('Unexpected React render error %o', error)

  return (
    <Box
      marginLeft={marginLeft}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        height: 'calc(100vh - 121px)',
      }}
    >
      <Grid container sx={{ marginTop: '-60px' }}>
        <Grid item xs={12}>
          <WarningAmber sx={{ fontSize: '92px' }} color="disabled" />
        </Grid>
        <Grid item xs={12} justifyContent="center" alignItems="center">
          <Typography variant="subtitle1">{errorMessage}</Typography>
          <Typography variant="body1">{secondaryMessage}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}

interface FullPageErrorProps {
  marginLeft?: number | string
  errorMessage?: string
  secondaryMessage?: string
}
